<template>
  <div class="PhoneVerifying__wrapper">
    <div class="PhoneVerifying__title">Verify your account</div>
    <div class="PhoneVerifying__description">
      To help you connect with friends & family and for security purposes,
      please verify your phone number.
    </div>

    <div class="Grid-row">
      <div class="PhoneVerifying__field-with-select">
        <country-select :selected.sync="selectedCountry"> </country-select>

        <div class="PhoneVerifying__country-code">+{{ phoneCode }}</div>

        <input
          v-focus
          type="text"
          name="phone_number"
          class="field"
          :class="{
            'is-danger': invalid && (errors.has('phone_number') || error),
          }"
          placeholder="Your Number"
          v-model="phone_number"
          v-validate="'required'"
          data-vv-delay="500"
          data-vv-as="Phone Number"
          ref="phone_number"
          @keyup.enter.prevent="submit"
        />
      </div>
      <span
        v-show="invalid && (errors.has('phone_number') || error)"
        class="form-help is-danger"
      >
        {{ errors.first("phone_number") || error }}
      </span>
    </div>

    <div class="Grid-row">
      <div class="form-actions" :style="'flex-direction: column; gap: 8px;'">
        <a
          @click="submit"
          class="submit-btn"
          :class="{ processing: processing }"
        >
          Send The Code
        </a>
        <span id="recaptcha-container"></span>
      </div>
    </div>
    <a class="PhoneVerifying__go-back" @click="goTo('login')">
      Back to login
    </a>
    <transition name="appear-from-bottom" mode="out-in">
      <div class="PhoneVerifying__processing" v-if="processing">
        <spinner />
      </div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import CountrySelect from "@/components/common/country-select/country-select";
import Spinner from "@/components/common/spinner/spinner";
import { COUNTRIES } from "@/constants/countries.constant";

export default {
  components: {
    CountrySelect,
    Spinner,
  },
  data() {
    return {
      phone_number: null,
      processing: false,
      selectedCountry: null,
      countries: [],
      error: null,
      invalid: false,
    };
  },
  computed: {
    phoneCode() {
      return this.selectedCountry
        ? COUNTRIES[this.selectedCountry]?.code
        : null;
    },
  },

  watch: {
    selectedCountry(value) {
      localStorage.setItem("phone_code", value);
    },
  },

  methods: {
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          this.invalid = false;
          this.$store
            .dispatch("checkPhoneNumber", {
              phone_number: this.phone_number,
              country_code: this.selectedCountry,
            })
            .then((valid_number) => {
              if (valid_number) {
                this.$store.dispatch("verifyPhone", {
                  phone_number: valid_number,
                  callback: (data) => {
                    if (data) {
                      if (
                        data ===
                        "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section."
                      ) {
                        this.error = "This number has been blocked.";
                      } else {
                        this.error = data;
                      }
                      this.invalid = true;
                      this.processing = false;
                    } else {
                      router.push({ name: "signup-code-verifying" });
                    }
                  },
                });
              } else {
                this.error = "Phone Number is not valid.";
                this.invalid = true;
                this.processing = false;
              }
            });
        } else {
          this.invalid = true;
        }
      });
    },
    goTo(name) {
      router.push({ name });
    },
  },
  mounted() {
    if (localStorage.getItem("phone_code")) {
      this.selectedCountry = localStorage.getItem("phone_code");
    }
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
