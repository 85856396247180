/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/light");

var $root = ($protobuf.roots["default"] || ($protobuf.roots["default"] = new $protobuf.Root()))
.addJSON({
  authentication: {
    options: {
      swift_prefix: ""
    },
    nested: {
      SignUpRequest: {
        oneofs: {
          method: {
            oneof: [
              "email",
              "phone",
              "facebook",
              "firebase"
            ]
          }
        },
        fields: {
          clientId: {
            type: "string",
            id: 1
          },
          scopes: {
            rule: "repeated",
            type: "string",
            id: 2
          },
          email: {
            type: "EmailSignUpData",
            id: 3
          },
          phone: {
            type: "PhoneSignUpData",
            id: 4
          },
          facebook: {
            type: "FacebookSignUpData",
            id: 5
          },
          firebase: {
            type: "FirebaseSignUpData",
            id: 6
          },
          recaptcha: {
            type: "ReCaptchaToken",
            id: 7
          }
        },
        nested: {
          EmailSignUpData: {
            fields: {
              email: {
                type: "string",
                id: 1
              },
              name: {
                type: "string",
                id: 2
              },
              username: {
                type: "string",
                id: 3
              },
              password: {
                type: "string",
                id: 4
              }
            }
          },
          PhoneSignUpData: {
            fields: {
              OBSOLETECountryCode: {
                type: "string",
                id: 1,
                options: {
                  deprecated: true
                }
              },
              OBSOLETEPhone: {
                type: "string",
                id: 2,
                options: {
                  deprecated: true
                }
              },
              name: {
                type: "string",
                id: 3
              },
              username: {
                type: "string",
                id: 4
              },
              password: {
                type: "string",
                id: 5
              },
              firebaseToken: {
                type: "string",
                id: 6
              }
            }
          },
          FacebookSignUpData: {
            fields: {
              facebookToken: {
                type: "string",
                id: 1
              }
            }
          },
          FirebaseSignUpData: {
            fields: {
              firebaseToken: {
                type: "string",
                id: 1
              },
              accountCreationMode: {
                type: "AccountCreationMode",
                id: 2
              }
            },
            nested: {
              AccountCreationMode: {
                values: {
                  NONE: 0,
                  MERGE: 1,
                  ADD: 2
                }
              }
            }
          }
        }
      },
      SignUpResponse: {
        oneofs: {
          response: {
            oneof: [
              "token",
              "unconfirmed"
            ]
          }
        },
        fields: {
          token: {
            type: "com.newmedia.auth.model.TokenResponse",
            id: 1
          },
          unconfirmed: {
            type: "ConfirmationRequired",
            id: 2
          }
        },
        nested: {
          ConfirmationRequired: {
            fields: {
              message: {
                type: "string",
                id: 1
              },
              quickMailVerificationToken: {
                type: "string",
                id: 2
              }
            }
          }
        }
      },
      SignUpError: {
        fields: {
          code: {
            type: "ErrorCode",
            id: 1
          },
          message: {
            type: "string",
            id: 2
          }
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              PERFORMED_BY_UNAUTHORIZED_APP: 1,
              UNAVAILABLE_SCOPE_REQUESTED: 2,
              VALIDATION_FAILED: 3,
              FB_CONNECTION_FAILURE: 4,
              FIREBASE_AUTH_ERROR: 5,
              FACEBOOK_ID_ALREADY_TAKEN: 6,
              FACEBOOK_EMAIL_ALREADY_TAKEN: 7,
              RECAPTCHA_VERIFICATION_FAILED: 8
            }
          }
        }
      },
      LogInRequest: {
        oneofs: {
          method: {
            oneof: [
              "email",
              "phone",
              "facebook",
              "username",
              "firebaseAuth",
              "quickMailVerification",
              "signInWithApple"
            ]
          }
        },
        fields: {
          clientId: {
            type: "string",
            id: 1
          },
          scopes: {
            rule: "repeated",
            type: "string",
            id: 2
          },
          email: {
            type: "EmailLogInData",
            id: 3
          },
          phone: {
            type: "PhoneLogInData",
            id: 4
          },
          facebook: {
            type: "FacebookLogInData",
            id: 5
          },
          username: {
            type: "UsernameLogInData",
            id: 6
          },
          firebaseAuth: {
            type: "FirebaseAuthLogInData",
            id: 7
          },
          quickMailVerification: {
            type: "QuickMailVerificationData",
            id: 8
          },
          signInWithApple: {
            type: "AppleSignInData",
            id: 10
          },
          recaptcha: {
            type: "ReCaptchaToken",
            id: 9
          }
        },
        nested: {
          EmailLogInData: {
            fields: {
              email: {
                type: "string",
                id: 1
              },
              password: {
                type: "string",
                id: 2
              }
            }
          },
          PhoneLogInData: {
            fields: {
              phoneNumber: {
                type: "string",
                id: 1
              },
              password: {
                type: "string",
                id: 2
              }
            }
          },
          FacebookLogInData: {
            fields: {
              facebookToken: {
                type: "string",
                id: 1
              }
            }
          },
          UsernameLogInData: {
            fields: {
              username: {
                type: "string",
                id: 2
              },
              password: {
                type: "string",
                id: 3
              }
            }
          },
          AppleSignInData: {
            fields: {
              idToken: {
                type: "string",
                id: 1
              },
              rawNonce: {
                type: "string",
                id: 2
              },
              name: {
                type: "string",
                id: 3
              }
            }
          },
          FirebaseAuthLogInData: {
            fields: {
              OBSOLETEPhoneNumber: {
                type: "string",
                id: 1,
                options: {
                  deprecated: true
                }
              },
              firebaseToken: {
                type: "string",
                id: 2
              }
            }
          },
          QuickMailVerificationData: {
            fields: {
              quickMailVerificationToken: {
                type: "string",
                id: 1
              }
            }
          }
        }
      },
      LogInResponse: {
        fields: {
          token: {
            type: "com.newmedia.auth.model.TokenResponse",
            id: 1
          }
        }
      },
      LogInError: {
        oneofs: {
          errorTypes: {
            oneof: [
              "unconfirmedUser"
            ]
          }
        },
        fields: {
          code: {
            type: "ErrorCode",
            id: 1
          },
          message: {
            type: "string",
            id: 2
          },
          unconfirmedUser: {
            type: "ErrrorUnconfirmedUser",
            id: 3
          }
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              PERFORMED_BY_UNAUTHORIZED_APP: 1,
              UNAVAILABLE_SCOPE_REQUESTED: 2,
              INVALID_EMAIL_AUTHENTICATION: 3,
              INVALID_PHONE_AUTHENTICATION: 4,
              INVALID_FACEBOOK_AUTHENTICATION: 5,
              INVALID_USERNAME_AUTHENTICATION: 6,
              UNCONFIRMED_USER: 7,
              FB_CONNECTION_FAILURE: 8,
              UNKNOWN_PHONE_NUMBER: 9,
              FIREBASE_AUTH_ERROR: 10,
              INVALID_QUICK_MAIL_VERIFICATION_TOKEN: 11,
              UNKNOWN_FACEBOOK_ID: 12,
              RECAPTCHA_VERIFICATION_FAILED: 13,
              APPLE_AUTH_ERROR: 14
            }
          },
          ErrrorUnconfirmedUser: {
            fields: {
              quickMailVerificationToken: {
                type: "string",
                id: 2
              }
            }
          }
        }
      },
      LegacyTokensResponse: {
        fields: {
          xAuthToken: {
            type: "string",
            id: 1
          }
        }
      },
      TokenUpgradeRequest: {
        fields: {
          clientId: {
            type: "string",
            id: 1
          },
          scopes: {
            rule: "repeated",
            type: "string",
            id: 2
          }
        }
      },
      TokenUpgradeResponse: {
        fields: {
          token: {
            type: "com.newmedia.auth.model.TokenResponse",
            id: 1
          }
        }
      },
      TokenUpgradeError: {
        fields: {
          code: {
            type: "ErrorCode",
            id: 1
          },
          message: {
            type: "string",
            id: 2
          }
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              PERFORMED_BY_UNAUTHORIZED_APP: 1,
              UNAVAILABLE_SCOPE_REQUESTED: 2
            }
          }
        }
      },
      RecoverPasswordRequest: {
        fields: {
          email: {
            type: "string",
            id: 1
          }
        }
      },
      RecoverPasswordResponse: {
        fields: {}
      },
      CountryCode: {
        fields: {
          countryCode: {
            type: "string",
            id: 1
          }
        }
      },
      ResendEmailRequest: {
        fields: {
          email: {
            type: "string",
            id: 1
          }
        }
      },
      ResendEmailResponse: {
        fields: {}
      },
      VerifyAuthenticationEmailRequest: {
        fields: {
          token: {
            type: "string",
            id: 1
          }
        }
      },
      VerifyAuthenticationEmailResponse: {
        fields: {}
      },
      VerifyAuthenticationEmailError: {
        fields: {
          code: {
            type: "ErrorCode",
            id: 1
          },
          message: {
            type: "string",
            id: 2
          }
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              WRONG_TOKEN_OR_USED: 1
            }
          }
        }
      },
      ConnectFacebookRequest: {
        fields: {
          token: {
            type: "string",
            id: 1
          }
        }
      },
      ConnectFacebookError: {
        fields: {
          code: {
            type: "ErrorCode",
            id: 1
          },
          message: {
            type: "string",
            id: 2
          }
        },
        nested: {
          ErrorCode: {
            values: {
              UNKNOWN: 0,
              FACEBOOK_ID_ALREADY_USED: 1,
              FIREBASE_AUTH_ERROR: 2
            }
          }
        }
      },
      ReCaptchaToken: {
        fields: {
          version: {
            type: "ReCaptchaVersion",
            id: 1
          },
          token: {
            type: "string",
            id: 2
          },
          platform: {
            type: "Platform",
            id: 3
          }
        },
        nested: {
          ReCaptchaVersion: {
            values: {
              V2: 0,
              V3: 1
            }
          },
          Platform: {
            values: {
              ANDROID: 0,
              IOS: 1,
              WEB: 2
            }
          }
        }
      }
    }
  },
  com: {
    nested: {
      newmedia: {
        nested: {
          auth: {
            nested: {
              model: {
                options: {
                  swift_prefix: "Proto_"
                },
                nested: {
                  ApplicationRequest: {
                    fields: {
                      applicationData: {
                        type: "ApplicationData",
                        id: 4
                      }
                    }
                  },
                  ApplicationResponse: {
                    fields: {
                      applicationName: {
                        type: "string",
                        id: 1
                      },
                      applicationUrl: {
                        type: "string",
                        id: 2
                      },
                      applicationAuthenticated: {
                        type: "bool",
                        id: 3
                      }
                    }
                  },
                  ApplicationError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      userMessage: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          WRONG_APPLICATION_DATA: 1
                        }
                      }
                    }
                  },
                  AuthRequest: {
                    fields: {
                      scope: {
                        rule: "repeated",
                        type: "string",
                        id: 1
                      },
                      responseType: {
                        type: "ResponseType",
                        id: 2
                      },
                      applicationData: {
                        type: "ApplicationData",
                        id: 4
                      }
                    },
                    nested: {
                      ResponseType: {
                        values: {
                          UNKNOWN: 0,
                          CODE: 1
                        }
                      }
                    }
                  },
                  AuthResponse: {
                    fields: {
                      code: {
                        type: "string",
                        id: 1
                      }
                    }
                  },
                  AuthError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      userMessage: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          WRONG_APPLICATION_DATA: 1
                        }
                      }
                    }
                  },
                  TokenRequest: {
                    fields: {
                      clientId: {
                        type: "string",
                        id: 1
                      },
                      grantType: {
                        type: "GrantType",
                        id: 2
                      },
                      code: {
                        type: "string",
                        id: 3
                      },
                      refreshToken: {
                        type: "string",
                        id: 4
                      }
                    },
                    nested: {
                      GrantType: {
                        values: {
                          UNKNOWN: 0,
                          CODE: 1,
                          REFRESH_TOKEN: 2
                        }
                      }
                    }
                  },
                  TokenResponse: {
                    fields: {
                      accessToken: {
                        type: "string",
                        id: 1
                      },
                      expiresInMillis: {
                        type: "uint32",
                        id: 2
                      },
                      refreshToken: {
                        type: "string",
                        id: 3
                      }
                    }
                  },
                  TokenError: {
                    fields: {
                      code: {
                        type: "Code",
                        id: 1
                      },
                      userMessage: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      Code: {
                        values: {
                          UNKNOWN: 0,
                          LOGGED_OUT: 1
                        }
                      }
                    }
                  },
                  CertsResponse: {
                    fields: {
                      certificates: {
                        rule: "repeated",
                        type: "Certificate",
                        id: 1
                      }
                    },
                    nested: {
                      Certificate: {
                        fields: {
                          kid: {
                            type: "string",
                            id: 1
                          },
                          pemCertificate: {
                            type: "string",
                            id: 2
                          }
                        }
                      }
                    }
                  },
                  ApplicationData: {
                    oneofs: {
                      applicationData: {
                        oneof: [
                          "ios",
                          "android"
                        ]
                      }
                    },
                    fields: {
                      ios: {
                        type: "Ios",
                        id: 1
                      },
                      android: {
                        type: "Android",
                        id: 2
                      }
                    },
                    nested: {
                      Ios: {
                        fields: {
                          clientId: {
                            type: "string",
                            id: 1
                          },
                          redirectUri: {
                            type: "string",
                            id: 2
                          },
                          applicationBundleId: {
                            type: "string",
                            id: 3
                          }
                        }
                      },
                      Android: {
                        fields: {
                          clientId: {
                            type: "string",
                            id: 1
                          },
                          packageName: {
                            type: "string",
                            id: 2
                          },
                          applicationSignatureSha1: {
                            type: "string",
                            id: 3
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          developer: {
            options: {
              swift_prefix: ""
            },
            nested: {
              ApplicationType: {
                values: {
                  ios: 0,
                  android: 1,
                  web: 2
                }
              },
              MinimalProjectData: {
                fields: {
                  projectId: {
                    type: "string",
                    id: 1
                  },
                  applicationTypes: {
                    rule: "repeated",
                    type: "ApplicationType",
                    id: 2
                  },
                  name: {
                    type: "string",
                    id: 3
                  },
                  email: {
                    type: "string",
                    id: 4
                  }
                }
              },
              AndroidApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationPackageName: {
                    type: "string",
                    id: 4
                  },
                  applicationSignatureSha1: {
                    type: "string",
                    id: 5
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 6
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 7
                  }
                }
              },
              IosApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationBundleId: {
                    type: "string",
                    id: 4
                  },
                  applicationRedirectUri: {
                    type: "string",
                    id: 5
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 6
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 7
                  }
                }
              },
              WebApplication: {
                fields: {
                  applicationId: {
                    type: "string",
                    id: 1
                  },
                  applicationClientId: {
                    type: "string",
                    id: 2
                  },
                  applicationName: {
                    type: "string",
                    id: 3
                  },
                  applicationWebsiteUrl: {
                    type: "string",
                    id: 4
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 5
                  },
                  applicationReviewed: {
                    type: "bool",
                    id: 6
                  }
                }
              },
              Application: {
                oneofs: {
                  applicationData: {
                    oneof: [
                      "android",
                      "ios",
                      "web"
                    ]
                  }
                },
                fields: {
                  android: {
                    type: "AndroidApplication",
                    id: 1
                  },
                  ios: {
                    type: "IosApplication",
                    id: 2
                  },
                  web: {
                    type: "WebApplication",
                    id: 3
                  }
                }
              },
              CreateAndroidApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationPackageName: {
                    type: "string",
                    id: 2
                  },
                  applicationSignatureSha1: {
                    type: "string",
                    id: 3
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 4
                  }
                }
              },
              CreateIosApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationBundleId: {
                    type: "string",
                    id: 2
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 3
                  }
                }
              },
              CreateWebApplication: {
                fields: {
                  applicationName: {
                    type: "string",
                    id: 1
                  },
                  applicationWebsiteUrl: {
                    type: "string",
                    id: 2
                  },
                  applicationIconUrl: {
                    type: "string",
                    id: 3
                  }
                }
              },
              DeveloperProjectListResponse: {
                fields: {
                  projects: {
                    rule: "repeated",
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              CreateProjectRequest: {
                fields: {
                  name: {
                    type: "string",
                    id: 1
                  },
                  email: {
                    type: "string",
                    id: 2
                  },
                  userId: {
                    type: "string",
                    id: 3
                  }
                }
              },
              CreateProjectResponse: {
                fields: {
                  project: {
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              CreateProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0
                    }
                  }
                }
              },
              DeleteProjectResponse: {
                fields: {
                  project: {
                    type: "MinimalProjectData",
                    id: 1
                  }
                }
              },
              DeleteProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      PROJECT_NOT_FOUND: 1
                    }
                  }
                }
              },
              ProjectResponse: {
                fields: {
                  projectId: {
                    type: "string",
                    id: 1
                  },
                  applications: {
                    rule: "repeated",
                    type: "Application",
                    id: 2
                  },
                  email: {
                    type: "string",
                    id: 3
                  },
                  projectName: {
                    type: "string",
                    id: 4
                  }
                }
              },
              ProjectError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              CreateApplicationRequest: {
                oneofs: {
                  applicationData: {
                    oneof: [
                      "ios",
                      "android",
                      "web"
                    ]
                  }
                },
                fields: {
                  ios: {
                    type: "CreateIosApplication",
                    id: 1
                  },
                  android: {
                    type: "CreateAndroidApplication",
                    id: 2
                  },
                  web: {
                    type: "CreateWebApplication",
                    id: 3
                  }
                }
              },
              CreateApplicationResponse: {
                fields: {
                  application: {
                    type: "Application",
                    id: 1
                  }
                }
              },
              CreateApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              UpdateApplicationRequest: {
                fields: {
                  newName: {
                    type: "string",
                    id: 1
                  },
                  applicationType: {
                    type: "ApplicationType",
                    id: 2
                  }
                }
              },
              UpdateApplicationResponse: {
                fields: {
                  application: {
                    type: "Application",
                    id: 1
                  }
                }
              },
              UpdateApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              DeleteApplicationError: {
                fields: {
                  code: {
                    type: "Code",
                    id: 1
                  },
                  userMessage: {
                    type: "string",
                    id: 2
                  }
                },
                nested: {
                  Code: {
                    values: {
                      UNKNOWN: 0,
                      NOT_FOUND: 1
                    }
                  }
                }
              },
              WebApplicationInfo: {
                fields: {},
                nested: {
                  Response: {
                    fields: {
                      applicationClientId: {
                        type: "string",
                        id: 1
                      },
                      applicationName: {
                        type: "string",
                        id: 2
                      },
                      applicationImageUrl: {
                        type: "string",
                        id: 3
                      },
                      applicationWebsiteUrl: {
                        type: "string",
                        id: 4
                      },
                      projectName: {
                        type: "string",
                        id: 5
                      }
                    }
                  },
                  Error: {
                    fields: {
                      code: {
                        type: "Code",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      Code: {
                        values: {
                          UNKNOWN: 0,
                          APPLICATION_NOT_FOUND: 1,
                          PROJECT_NOT_FOUND: 2
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          profile: {
            nested: {
              model: {
                options: {
                  optimize_for: "LITE_RUNTIME",
                  swift_prefix: ""
                },
                nested: {
                  Gender: {
                    values: {
                      UNKNOWN: 0,
                      MALE: 1,
                      FEMALE: 2
                    }
                  },
                  Profile: {
                    fields: {
                      user: {
                        type: "user.User",
                        id: 1
                      },
                      gender: {
                        type: "Gender",
                        id: 2
                      },
                      email: {
                        type: "Email",
                        id: 3
                      },
                      countryCode: {
                        type: "string",
                        id: 17
                      },
                      phoneNumber: {
                        type: "string",
                        id: 4
                      },
                      birthDateInMillis: {
                        type: "int64",
                        id: 5
                      },
                      connectedToFacebook: {
                        type: "bool",
                        id: 9
                      },
                      facebookName: {
                        type: "string",
                        id: 19
                      },
                      hasPassword: {
                        type: "bool",
                        id: 10
                      },
                      blockedUserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 11
                      },
                      hiddenUserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 12
                      },
                      followedSuperuserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 13
                      },
                      subscribedSuperuserIds: {
                        rule: "repeated",
                        type: "string",
                        id: 14
                      },
                      barcodeId: {
                        type: "string",
                        id: 18
                      },
                      barcodeColor: {
                        type: "string",
                        id: 7
                      },
                      church: {
                        type: "string",
                        id: 8
                      },
                      cloudSpace: {
                        type: "uint32",
                        id: 15
                      },
                      usedSpace: {
                        type: "uint32",
                        id: 16
                      }
                    },
                    nested: {
                      Email: {
                        fields: {
                          address: {
                            type: "string",
                            id: 1
                          },
                          verified: {
                            type: "bool",
                            id: 2
                          }
                        }
                      }
                    }
                  },
                  ProfileResponse: {
                    fields: {
                      profile: {
                        type: "Profile",
                        id: 1
                      }
                    }
                  },
                  ProfileUpdateRequest: {
                    fields: {
                      name: {
                        type: "string",
                        id: 1
                      },
                      username: {
                        type: "string",
                        id: 2
                      },
                      email: {
                        type: "string",
                        id: 3
                      },
                      avatarUrl: {
                        type: "string",
                        id: 4
                      },
                      password: {
                        type: "string",
                        id: 5
                      },
                      gender: {
                        type: "Gender",
                        id: 6
                      },
                      bio: {
                        type: "BioChange",
                        id: 7
                      },
                      privateAccount: {
                        type: "PrivacyChange",
                        id: 8
                      },
                      birthDate: {
                        type: "BirthDateChange",
                        id: 9
                      },
                      phoneNumber: {
                        type: "PhoneNumberChange",
                        id: 10
                      },
                      facebook: {
                        type: "FacebookChange",
                        id: 11
                      }
                    },
                    nested: {
                      PrivacyChange: {
                        fields: {
                          privateAccount: {
                            type: "bool",
                            id: 1
                          }
                        }
                      },
                      BirthDateChange: {
                        fields: {
                          birthDate: {
                            type: "int64",
                            id: 1
                          }
                        }
                      },
                      BioChange: {
                        fields: {
                          bio: {
                            type: "string",
                            id: 1
                          }
                        }
                      },
                      PhoneNumberChange: {
                        fields: {
                          OBSOLETECountryCode: {
                            type: "string",
                            id: 1,
                            options: {
                              deprecated: true
                            }
                          },
                          OBSOLETEPhone: {
                            type: "string",
                            id: 2,
                            options: {
                              deprecated: true
                            }
                          },
                          firebaseToken: {
                            type: "string",
                            id: 3
                          }
                        }
                      },
                      FacebookChange: {
                        fields: {
                          facebookToken: {
                            type: "string",
                            id: 1
                          }
                        }
                      }
                    }
                  },
                  ProfileUpdateResponse: {
                    fields: {
                      profile: {
                        type: "Profile",
                        id: 1
                      }
                    }
                  },
                  ProfileUpdateError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      },
                      formErrors: {
                        rule: "repeated",
                        type: "FormValidationError",
                        id: 3
                      }
                    },
                    nested: {
                      FormValidationError: {
                        fields: {
                          code: {
                            type: "ErrorCode",
                            id: 1
                          },
                          message: {
                            type: "string",
                            id: 2
                          }
                        },
                        nested: {
                          ErrorCode: {
                            values: {
                              UNKNOWN: 0,
                              NAME: 1,
                              USERNAME: 2,
                              PHONE_NUMBER: 3,
                              PHONE_HASH: 4,
                              FACEBOOK_ID: 5,
                              PASSWORD: 6
                            }
                          }
                        }
                      },
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          VALIDATION_FAILED: 1,
                          FB_CONNECTION_FAILURE: 2,
                          FIREBASE_AUTH_ERROR: 3,
                          EMAIL_ALREADY_CONFIRMED: 4
                        }
                      }
                    }
                  },
                  VerifyEmailRequest: {
                    fields: {}
                  },
                  VerifyEmailResponse: {
                    fields: {}
                  },
                  VerifyEmailError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          EMAIL_TAKEN: 1,
                          NO_PENDING_VERIFICATION: 2
                        }
                      }
                    }
                  },
                  VerifyPhoneRequest: {
                    fields: {
                      countryCode: {
                        type: "string",
                        id: 1
                      },
                      phone: {
                        type: "string",
                        id: 2
                      }
                    }
                  },
                  VerifyPhoneResponse: {
                    fields: {}
                  },
                  VerifyPhoneError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      message: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          INVALID_FORMAT: 1,
                          NUMBER_TAKEN: 2
                        }
                      }
                    }
                  },
                  ProfileBadgeResponse: {
                    fields: {
                      id: {
                        type: "string",
                        id: 1
                      },
                      title: {
                        type: "string",
                        id: 2
                      },
                      description: {
                        type: "string",
                        id: 3
                      },
                      imageUrl: {
                        type: "string",
                        id: 4
                      },
                      signature: {
                        type: "bool",
                        id: 5
                      }
                    }
                  },
                  ProfileBadgeError: {
                    fields: {
                      code: {
                        type: "ErrorCode",
                        id: 1
                      },
                      userMessage: {
                        type: "string",
                        id: 2
                      }
                    },
                    nested: {
                      ErrorCode: {
                        values: {
                          UNKNOWN: 0,
                          NOT_FOUND: 1
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  user: {
    options: {
      java_package: "com.appunite.user.model",
      java_multiple_files: true,
      optimize_for: "LITE_RUNTIME",
      java_generate_equals_and_hash: true,
      java_outer_classname: "UserModule",
      swift_prefix: ""
    },
    nested: {
      PresenceMessage: {
        oneofs: {
          presence: {
            oneof: [
              "online",
              "offline",
              "neverSeen"
            ]
          }
        },
        fields: {
          online: {
            type: "Online",
            id: 2
          },
          offline: {
            type: "Offline",
            id: 3
          },
          neverSeen: {
            type: "NeverSeen",
            id: 4
          }
        },
        nested: {
          Online: {
            fields: {}
          },
          Offline: {
            fields: {
              lastSeenInMillis: {
                rule: "required",
                type: "int64",
                id: 1
              }
            }
          },
          NeverSeen: {
            fields: {}
          }
        }
      },
      User: {
        fields: {
          userId: {
            rule: "required",
            type: "string",
            id: 1
          },
          name: {
            type: "string",
            id: 2
          },
          username: {
            type: "string",
            id: 3
          },
          postsCount: {
            type: "int32",
            id: 4
          },
          superuser: {
            type: "Superuser",
            id: 5
          },
          userBio: {
            type: "string",
            id: 6
          },
          avatarUrl: {
            type: "string",
            id: 7
          },
          avatarMainColor: {
            type: "int32",
            id: 8
          },
          privateAccount: {
            type: "bool",
            id: 9,
            options: {
              "default": false
            }
          },
          badgeIds: {
            rule: "repeated",
            type: "string",
            id: 10
          },
          presence: {
            type: "PresenceMessage",
            id: 11
          }
        },
        nested: {
          Superuser: {
            fields: {
              DEPRECATEDWebsiteUrl: {
                type: "string",
                id: 1
              },
              location: {
                type: "string",
                id: 2
              },
              coverUrl: {
                type: "string",
                id: 3
              },
              accountType: {
                type: "string",
                id: 4
              },
              followersCount: {
                type: "int32",
                id: 5
              },
              contactEmail: {
                type: "string",
                id: 6
              },
              contactPhoneNumber: {
                type: "string",
                id: 7
              }
            }
          }
        }
      },
      UserResponse: {
        fields: {
          user: {
            rule: "required",
            type: "user.User",
            id: 1
          }
        }
      },
      PostSuperusersSharersResponse: {
        fields: {
          userIds: {
            rule: "repeated",
            type: "string",
            id: 1
          },
          users: {
            rule: "repeated",
            type: "user.User",
            id: 2
          }
        }
      },
      GetUserByUsernameResponse: {
        fields: {
          userId: {
            type: "string",
            id: 1
          },
          users: {
            rule: "repeated",
            type: "user.User",
            id: 2
          }
        }
      },
      GetUserByUsernameError: {
        fields: {
          code: {
            type: "Code",
            id: 1
          },
          userMessage: {
            type: "string",
            id: 2
          }
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              USER_NOT_FOUND: 1
            }
          }
        }
      },
      GetConferenceUrlResponse: {
        fields: {
          conferenceUrl: {
            type: "string",
            id: 1
          }
        }
      },
      GetConferenceUrlError: {
        fields: {
          code: {
            type: "Code",
            id: 1
          },
          userMessage: {
            type: "string",
            id: 2
          }
        },
        nested: {
          Code: {
            values: {
              UNKNOWN: 0,
              CONFERENCE_URL_NOT_FOUND: 1
            }
          }
        }
      },
      UsersResponse: {
        fields: {
          users: {
            rule: "repeated",
            type: "User",
            id: 1
          }
        }
      }
    }
  }
});

module.exports = $root;
